.hero {
    background-color: #222529;
    margin: 70px 85px;
}
.hero p.grey-text {
    color: #9a9999;
    font-size: 1.3em;
    margin-bottom: 30px;
}
.hero h1 {
    color: white;
    font-size: 3em;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.hero h2 {
    color: white;
}
div.hero p {
    margin-bottom: 10px;
    color: #f3f3f3;
    max-width: 530px;
}