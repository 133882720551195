.work {
    background-color: #222529;
    padding: 70px 85px 50px 20px;
}
.work h2 {
    color: white;
    padding-left: 30px;
}
div.work div.card {
    border: 1px solid #222529;
}
div.work div.card-body {
    background-color: #222529;
    transition: .2s;
}
div.work div.card-body:hover {
    background-color: rgb(34, 37, 41, .9);
}
div.work div.card:hover {
    border: 1px solid #222529;
}
div.card-body-left,
div.card-body-right {
    display: inline-block;
    vertical-align: top;
}
div.card-body-left {
    vertical-align: text-top;
    min-width: 9vw;
}
.card-body-container {
    display: flex;
}
div p.date-text {
    color: grey;
    margin: 0;
}
h3.body-text,
p.body-text {
    font-family: Montserrat, sans-serif;
    color: #f3f3f3;
    width: 100%;
}
h3.body-text {
    font-size: 1.5em;
}