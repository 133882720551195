@media screen and (max-width: 1080px) {
    div.main-container {
        flex-direction: column;
        height: auto;
    }
    .fixed-container {
        position: relative;
        width: 100%;
        height: auto;
    }
    .scroll-container {
        width: 100%;
        margin-left: 0;
        height: auto;
    }
    div.hero p {
        max-width: 325px;
    }
    div.hero {
        margin: 40px;
    }
    .navbar-brand {
        color: #f3f3f3;
        font-weight: bold;
        font-size: 1.2em;
        letter-spacing: 1px;
    }
    div.projects,
    div.skills {
        margin: 60px 40px 40px 40px;
    }
    div.contactForm {
        margin: 0 40px 40px 40px;
    }
    div.accordion-body.accordionBody {
        padding: 25px;
    }
    div.card-body.cardBody {
        padding: 25px;
    }
    div.cardBody h3.secondary-heading {
        margin-top: 20px;
    }
    div.card-body.cardBody p,
    div.card-body.cardBody ul {
        margin-bottom: 0;
    }
    footer.footer {
        margin: 40px 40px 20px 40px;
    }
    div.work {
        padding: 10px;
    }
    div h3.body-text,
    div p.body-text {
        min-width: 100%;
    }
    p.date-text {
        min-width: 200px;
    }
}
@media screen and (max-width: 930px) {
    div.work div.card-body-right {
        margin-left: 0;
    }
}
@media screen and (max-width: 865px) {
    div.card-body-left,
    div.card-body-right {
        display: block;
    }
    div.card-body-right {
        overflow: hidden;
    }
    p.date-text {
        padding-bottom: 10px;
    }
}
@media screen and (max-width: 629px) {
    div.vstack {
        padding: 0;
        margin: 0;
    }
    div.work {
        padding-right: 0;
    }
}
@media screen and (max-width: 592px) {
    div.hero p.grey-text {
        font-size: 1.1em;
    }
    div.hero h1 {
        font-size: 2.5em;
    }
    div.hero h2 {
        font-size: 1.6em;
    }
    .navbar-brand {
        font-size: 1.1em;
    }
    div.icon-nav-bar .fa-linkedin,
    div.icon-nav-bar .fa-facebook,
    div.icon-nav-bar .fa-instagram,
    div.icon-nav-bar .fa-twitter {
        color: #3B5998;
        font-size: 28px;
        margin-right: 20px;
        cursor: pointer;
        transition: .2s;
    }
    div.cardBody.card-body div.card-title h3.grey-text {
        font-size: 1.1em;
    }
    div.card-body p.date-text {
        font-size: 1em;
    }
    div.card-body.card-body h3.body-text {
        font-size: 1.3em;
    }
    div.card-body.card-body p.body-text {
        font-size: 1em;
    }
    div.cardBody.card-body p {
        font-size: 1em;
    }
    div.cardBody.card-body h3 {
        font-size: 1.4em;
    }
    div.card-body-right p.body-text {
        font-size: 1.1em;
    }
    div.mb-3 label {
        font-size: 1em;
    }

    .mx-5 {
        justify-content: start;
        margin: 0;
    }
    div.card-body-right {
        max-width: 100%;
    }
    div.hero {
        margin: 20px;
    }
    div.projects,
    div.skills,
    footer.footer {
        margin: 60px 20px 20px 20px;
    }
    div.contactForm {
        margin: 20px 20px 20px 20px;
    }
    div.accordion-body.accordionBody {
        padding: 15px;
    }
    div.card-body.cardBody {
        padding: 15px;
    }
    div.work {
        margin-top: 60px;
    }
    div.work h2 {
        padding-left: 10px;
    }
    div.animated-box {
        padding: 10px;
    }
    div.animated-box p.footer-text {
        margin: 0;
    }
    div.work div.card-body {
        padding: 10px;
    }
}
@media screen and (max-width: 575px) {
    div.col-sm-4 {
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 430px) {
    div.card-body {
        padding: 10px;
    }
    div.accordion-body {
        padding : 10px;
    }
}