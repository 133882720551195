div.icon-nav-bar {
    display: flex;
    margin-top: 45px;
}

.fa-linkedin,
.fa-facebook,
.fa-instagram,
.fa-twitter {
    color: #3B5998;
    font-size: 30px;
    margin-right: 20px;
    cursor: pointer;
    transition: .2s;
}
.fa-linkedin:hover,
.fa-facebook:hover,
.fa-instagram:hover,
.fa-twitter:hover {
    color: #0d6efd;
}