.skills {
    background-color: #222529;
    margin: 70px 85px 20px 50px;
}
div.skills h2 {
    color: white;
    margin-bottom: 30px;
}

div.list-group .list-group-item p {
    margin-bottom: 10px;
    margin-top: 10px;
}
div.listItemContainer {
    display: flex;
    align-items: center;
}
.icon-container {
    width: 20px;
}
div.listItemContainer p {
    margin-left: 20px
}

div.col-sm-8 {
    color: white;
}
.grey-text {
    color: #222529;
    font-variant: small-caps;
}

.fa-react {
    color: #61DBFB;
}
.fa-angular {
    color: #a6120d;
}
.fa-java {
    color: #5382a1;
}
.fa-php {
    color: #8993be;
}
.fa-html5 {
    color: #e34c26;
}
.fa-css {
    color: #264de4;
}