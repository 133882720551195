.projects {
    background-color: #222529;
    margin: 70px 85px 70px 50px;
}
div.projects h2 {
    color: white;
    margin-bottom: 30px;
}
div.projects h2.accordion-header {
    margin-bottom: 0;
}
div.accordion-body {
    padding : 30px;
}

div.accordion-item {
    background-color: #3d434a;
    color: #f3f3f3;
    border: 1px solid #222529;
}

div.accordion-item > h2.accordion-header {
    background-color: #3d434a;
    color: #f3f3f3;
}
button.accordion-button {
    background-color: #3d434a;
    color: #f3f3f3;
}
.accordion-button:not(.collapsed) {
    background-color: rgb(174, 181, 188); /* Text color */
    box-shadow: none;
}

div.accordion-item h2.accordion-header {
    background-color: #222529;
}

div.card-body {
    padding: 30px;
}
div.card-body h3 {
    font-weight: bold;
}
div.card-body p {
    font-family: Montserrat, sans-serif;
    font-size: 1.2em;
    letter-spacing: 1px;
    margin-top: 20px;
}