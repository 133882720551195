.contactForm {
    background-color: #222529;
    margin: 0 85px 70px 50px;
}
div.contactForm h1 {
    color: white;
    margin-bottom: 2rem;
}

div.mb-3 label {
   color: white;
}
span.error-text {
    color: red;
}
.form-control {
    margin-bottom: 10px;
    width: 100%;
}
.custom-button {
    margin-top: 20px;
}